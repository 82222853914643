/* App.css */

body, html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: monospace;
}

.App {
  text-align: center;
  width: 100%;
  max-width: 600px; /* Adjust based on your preference */
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  font-family: monospace
}

.location {
  background-color: #9d30fd;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  margin: 20px;
}

h2 {
  color: #333; /* Dark grey text */
  font-size: 24px; /* Larger font size */
  margin-bottom: 20px; /* Spacing below the heading */
  font-family: monospace;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button-row {
  display: flex;
  justify-content: space-around; /* This spreads the buttons evenly across the row */
  margin-bottom: 10px; /* Adds some space between this row and anything below it */
}

